import { default as automate } from './automate';
import { default as xfai } from './xfai';
import { default as magic } from './magic';

const themes = {
  automate,
  xfai,
  magic,
};

export default themes;
